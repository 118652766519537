/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { INPUT_FORMAT, DEFAULT_FORMAT } from '../../constants/timeFormats';

const DisplayTime = ({ time, format = DEFAULT_FORMAT, timezone = 'local' }) => {
  let parsedTime;
  let timezoneAbbr;

  if (timezone === 'UTC') {
    parsedTime = moment.utc(time, INPUT_FORMAT);
    timezoneAbbr = 'UTC';
  } else if (timezone === 'CST') {
    parsedTime = moment.utc(time, INPUT_FORMAT).utcOffset(-6, false);
    timezoneAbbr = 'CST';
  } else {
    parsedTime = moment.utc(time, INPUT_FORMAT).local();
    timezoneAbbr = '';
  }

  const formattedTime = parsedTime.format(format);
  const timestamp = parsedTime.fromNow();

  return <>{`${formattedTime} (${timestamp}) ${timezoneAbbr}`}</>;
};

DisplayTime.propTypes = {
  time: PropTypes.string.isRequired,
  format: PropTypes.string,
  timezone: PropTypes.string,
};

export default DisplayTime;
