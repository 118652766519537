/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

const getPreferredTopics = (builder) =>
  builder.query({
    query: ({ feature, key, isCommonTopic }) => {
      const params = new URLSearchParams();
      params.append('feature', feature);
      params.append('key', key);
      params.append('isCommonTopic', isCommonTopic);
      return {
        url: `report-summary-topics?${params}`,
        method: 'GET',
      };
    },
  });

const updateListedPreferredTopics = (builder) =>
  builder.mutation({
    query: ({ key, companyWithPref }) => ({
      url: `listed-stocks/${key}`,
      method: 'PUT',
      body: JSON.stringify(companyWithPref),
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  });

const updateUnlistedPreferredTopics = (builder) =>
  builder.mutation({
    query: ({ key, companyWithPref }) => ({
      url: `report-sources/${key}`,
      method: 'PUT',
      body: JSON.stringify(companyWithPref),
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  });

export const getPreferredTopicsEPFactory = (builder) =>
  getPreferredTopics(builder);

export const updateListedPreferredTopicsEPFactory = (builder) =>
  updateListedPreferredTopics(builder);

export const updatedUnlistedPreferredTopicsEPFactory = (builder) =>
  updateUnlistedPreferredTopics(builder);
