/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Paper,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DialogActionsWrapper,
  HeadingGrid,
  ShadowBox,
  StyledDataGrid,
  StyledLoadingButton,
  StyledWrapper,
} from './miReportTopics.styled';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import EditIconSvg from '../../../assets/edit-icon';
import DeleteIconSvg from '../../../assets/delete-icon';
import AddIconSvg from '../../../assets/add-icon';
import ReportTopicsPopover from './components';
import PageContainer from '../../../components/pageContainer';
import { getTheme } from '../../../themes';
import MiNavigationDropdown from '../../../components/marketIntelligence/miNavigationDropdown';
import MiTabs from '../../../components/marketIntelligence/miTabs';
import {
  useAddReportSummaryTopicMutation,
  useDeleteReportSummaryTopicMutation,
  useLazyGetReportSummaryTopicsQuery,
  useUpdateReportSummaryTopicMutation,
} from '../../../redux/services/speciphicAsk';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MARKET_INTELLIGENCE_LISTED_FEATURE,
  MARKET_INTELLIGENCE_UNLISTED_FEATURE,
  MI_PARAMS,
} from '../../../constants/marketIntelligence';
import * as PATHS from '../../../constants/path.js';

const DETAIL_DATAGRID_LENGTH = 110;

const MiReportTopicsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { featureName } = useParams();
  const navigate = useNavigate();
  const [feature, setFeature] = useState(
    featureName === MI_PARAMS.UNLISTED
      ? MARKET_INTELLIGENCE_UNLISTED_FEATURE
      : MARKET_INTELLIGENCE_LISTED_FEATURE,
  );
  const [value, setValue] = useState(
    featureName === MI_PARAMS.UNLISTED ? 1 : 0,
  );
  const [anchorElForNewTopic, setAnchorElNewTopic] = useState(null);
  const [requestReceived, setRequestReceived] = useState(false);
  const [topicId, setTopicId] = useState(null);
  const [topicData, setTopicData] = useState({
    topicName: '',
    topicDetails: '',
    order: 0,
    isCommonTopic: true,
  });
  const [mode, setMode] = useState('add');
  const [openDeleteTopic, setOpenDeleteTopic] = useState(false);

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  useEffect(() => {
    setFeature(
      featureName === MI_PARAMS.UNLISTED
        ? MARKET_INTELLIGENCE_UNLISTED_FEATURE
        : MARKET_INTELLIGENCE_LISTED_FEATURE,
    );
    setValue(featureName === MI_PARAMS.UNLISTED ? 1 : 0);
  }, [featureName]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      setFeature(MARKET_INTELLIGENCE_UNLISTED_FEATURE);
    } else if (newValue == 0) {
      setFeature(MARKET_INTELLIGENCE_LISTED_FEATURE);
    }
    const mappedFeatureName =
      newValue === 1 ? MI_PARAMS.UNLISTED : MI_PARAMS.LISTED;

    navigate(`${PATHS.MI_REPORT_TOPICS}/${mappedFeatureName}`);
  };

  const [
    getReportSummaryTopic,
    {
      data: topics,
      isSuccess: isGetTopicsSuccess,
      isLoading: isGetTopicsLoading,
      isError: isGetTopicsError,
      isFetching: isGetTopicFetching,
    },
  ] = useLazyGetReportSummaryTopicsQuery();

  useEffect(() => {
    getReportSummaryTopic({
      feature: feature,
    });
  }, [feature]);

  useEffect(() => {
    if (isGetTopicsError) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.dataGrid.deleteErrorToast'),
          severity: 'error',
        }),
      );
    }
  }, [topics, isGetTopicsError]);

  // delete topic

  const [
    deleteTopic,
    {
      isError: isDeleteTopicError,
      isLoading: isDeleteTopicLoading,
      isSuccess: isDeleteTopicSuccess,
    },
  ] = useDeleteReportSummaryTopicMutation();

  const handleDeleteTopic = (topicIdDelete) => {
    closeDeleteDialog();
    deleteTopic({
      topicId: topicIdDelete,
    });
  };

  const openDeleteTopicDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteTopic(true);
    setTopicId(row?.id);
  };

  const closeDeleteDialog = () => {
    setOpenDeleteTopic(false);
  };

  useEffect(() => {
    if (isDeleteTopicSuccess) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.dataGrid.deleteSuccessToast'),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteTopicSuccess]);

  useEffect(() => {
    if (isDeleteTopicError) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.dataGrid.deleteErrorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteTopicError]);

  // add

  const [
    addReportSummaryTopic,
    {
      data: newTopic,
      isSuccess: isAddNewTopicSuccess,
      isLoading: isAddNewTopicLoading,
      isError: isAddNewTopicError,
    },
  ] = useAddReportSummaryTopicMutation();

  const [
    updateReportSummaryTopic,
    {
      data: updatedTopic,
      isSuccess: isUpdateTopicSuccess,
      isLoading: isUpdateTopicLoading,
      isError: isUpdateTopicError,
    },
  ] = useUpdateReportSummaryTopicMutation();

  const handleAddNewTopicClick = () => {
    setAnchorElNewTopic(event.currentTarget);
    setMode('add');
  };

  const handleUpdateClick = (e, row) => {
    setAnchorElNewTopic(event.currentTarget);
    setMode('edit');
    setTopicData({
      topicName: row?.topicName,
      topicDetails: row?.topicDetails,
      order: row?.order,
      isCommonTopic: row?.isCommonTopic,
    });
    setTopicId(row?.id);
  };

  const handleCloseTopicPopover = () => {
    setAnchorElNewTopic(null);
    setTopicData({
      topicName: '',
      topicDetails: '',
      order: 0,
      isCommonTopic: true,
    });
    setMode('add');
    setRequestReceived(false);
  };

  const handleNameChange = (e) => {
    setTopicData((prevData) => ({ ...prevData, topicName: e.target.value }));
  };

  const handleDetailsChange = (e) => {
    setTopicData((prevData) => ({
      ...prevData,
      topicDetails: e.target.value,
    }));
  };

  const handleOrderChange = (e) => {
    const newOrder = parseInt(e.target.value, 10);

    setTopicData((prevData) => ({
      ...prevData,
      order: isNaN(newOrder) ? 0 : newOrder,
    }));
  };

  const handleSwitchTopicType = (e) => {
    setTopicData((prev) => ({
      ...prev,
      isCommonTopic: e.target.checked,
    }));
  };

  const handleSubmit = () => {
    const data = {
      topicName: topicData?.topicName,
      topicDetails: topicData?.topicDetails,
      topicOrder: topicData?.order,
      feature: feature,
      isCommonTopic: topicData?.isCommonTopic,
    };

    if (mode === 'add') {
      addReportSummaryTopic(data);
    } else if (mode === 'edit') {
      updateReportSummaryTopic({
        topicName: data?.topicName,
        topicId: topicId,
        topicDetails: data?.topicDetails,
        topicOrder: data?.topicOrder,
        isCommonTopic: data?.isCommonTopic,
      });
    }
  };

  useEffect(() => {
    if (isAddNewTopicSuccess) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.addNew.successToast'),
          severity: 'success',
        }),
      );
      handleCloseTopicPopover();
    }
  }, [isAddNewTopicSuccess]);

  useEffect(() => {
    if (isUpdateTopicSuccess) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.edit.successToast'),
          severity: 'success',
        }),
      );
      handleCloseTopicPopover();
    }
  }, [isUpdateTopicSuccess]);

  useEffect(() => {
    if (isUpdateTopicError) {
      dispatch(
        pushToast({
          message: t('reportSummaryTopicsPage.edit.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isUpdateTopicError]);

  // data grid functions

  const getTopicName = (props) => {
    return <StyledWrapper>{props.row?.topicName}</StyledWrapper>;
  };

  const getTopicDetails = (props) => {
    const topicText = props?.row?.topicDetails ? props.row?.topicDetails : 'NA';

    const truncatedText =
      topicText.length > DETAIL_DATAGRID_LENGTH
        ? `${topicText.slice(0, DETAIL_DATAGRID_LENGTH)}...`
        : topicText;

    return (
      <StyledWrapper>
        <span>{truncatedText}</span>
      </StyledWrapper>
    );
  };

  const actionColumn = (props) => {
    return (
      <>
        <Tooltip
          title={t('reportSummaryTopicsPage.dataGrid.actionButton.update')}
          arrow
        >
          <StyledLoadingButton onClick={(e) => handleUpdateClick(e, props.row)}>
            <span>
              <EditIconSvg />
            </span>
          </StyledLoadingButton>
        </Tooltip>
        <Tooltip
          title={t('reportSummaryTopicsPage.dataGrid.actionButton.delete')}
          arrow
        >
          <StyledLoadingButton
            onClick={(e) => openDeleteTopicDialog(e, props.row)}
            loading={topicId === props.row?.id && isDeleteTopicLoading}
          >
            <span>
              <DeleteIconSvg />
            </span>
          </StyledLoadingButton>
        </Tooltip>
      </>
    );
  };

  // delete popup

  const DeleteDialogPopup = () => {
    return (
      <Dialog open={openDeleteTopic}>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>
              {t('reportSummaryTopicsPage.dataGrid.deletePopup.heading')}
            </AlertTitle>
            <AlertTitle>
              <strong>
                {t('reportSummaryTopicsPage.dataGrid.deletePopup.warning')}
              </strong>
            </AlertTitle>
          </Alert>
        </DialogContent>
        <DialogActionsWrapper>
          <Button variant="outlined" size="small" onClick={closeDeleteDialog}>
            {t('reportSummaryTopicsPage.dataGrid.deletePopup.cancelButtonText')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDeleteTopic(topicId)}
          >
            {t('reportSummaryTopicsPage.dataGrid.deletePopup.deleteButtonText')}
          </Button>
        </DialogActionsWrapper>
      </Dialog>
    );
  };

  const columns = [
    {
      field: 'topicName',
      headerName: 'Topic Name',
      width: 300,
      headerAlign: 'left',
      align: 'left',
      renderCell: getTopicName,
    },
    {
      field: 'topicDetails',
      headerName: 'Topic Details',
      width: 250,
      headerAlign: 'left',
      align: 'left',
      renderCell: getTopicDetails,
    },
    {
      field: 'order',
      headerName: 'Topic Order',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Updated By',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => (
        <StyledWrapper>
          {' '}
          <span>{params.row.lastUpdatedBy}</span>
        </StyledWrapper>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      headerAlign: 'center',
      align: 'center',

      renderCell: actionColumn,
    },
  ];

  const open = Boolean(anchorElForNewTopic);

  return (
    <ThemeProvider theme={getTheme('newMi')}>
      <PageContainer background={'secondary'}>
        <ShadowBox>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item>
              <MiTabs handleTabChange={handleTabChange} value={value} />
            </Grid>
            <Grid item>
              <MiNavigationDropdown />
            </Grid>
          </Grid>
          {isGetTopicFetching ? (
            <>
              <LinearProgress />
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', marginTop: 2 }}
              >
                {t(
                  'reportSummaryTopicsPage.loadingMessage.loadingFileCollections',
                )}{' '}
              </Typography>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} p={{ lg: 1 }}>
                <Paper
                  elevation={2}
                  square={false}
                  sx={{ backgroundColor: 'white', borderRadius: 2 }}
                >
                  <HeadingGrid item>
                    <Typography variant="h6" sx={{ margin: 2 }}>
                      {t('app.navbar.reportTopics') + ' '}
                      {topics?.length > 0 && <>({topics?.length})</>}
                    </Typography>
                    <Button
                      sx={{ borderRadius: '8px', margin: 1 }}
                      size="small"
                      variant="contained"
                      onClick={handleAddNewTopicClick}
                      startIcon={<AddIconSvg />}
                    >
                      {t('reportSummaryTopicsPage.dataGrid.customToolbar.add')}
                    </Button>
                  </HeadingGrid>
                  <StyledDataGrid
                    sx={{
                      borderColor: 'white',
                      margin: 2,
                    }}
                    autoHeight
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pagination
                    paginationMode="client"
                    page={page}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    rows={isGetTopicsSuccess ? topics : []}
                    disableSelectionOnClick
                    disableColumnMenu
                    columns={columns}
                    getRowId={(row) => row?.id}
                  />
                </Paper>
              </Grid>
              {/* <Grid
                container
                item
                lg={3}
                xs={12}
                spacing={1}
                justifyContent={'flex-start'}
              >
                <Grid item p={{ lg: 1 }}>
                  <MiReportStats />
                </Grid>
                <Grid item p={{ lg: 1 }}>
                  <MiRelatedEvents />
                </Grid>
              </Grid> */}
            </Grid>
          )}
          <DeleteDialogPopup />
          <ReportTopicsPopover
            open={open}
            initialData={topicData}
            mode={mode}
            anchorEl={anchorElForNewTopic}
            handleClose={handleCloseTopicPopover}
            handleTopicNameChange={handleNameChange}
            handleTopicDetailsChange={handleDetailsChange}
            handleTopicOrderChange={handleOrderChange}
            handleSwitchTopicType={handleSwitchTopicType}
            requestReceived={requestReceived}
            isLoading={isAddNewTopicLoading}
            sourceLabel={'Source'}
            feature={feature}
            onSubmit={handleSubmit}
          />
        </ShadowBox>
      </PageContainer>
    </ThemeProvider>
  );
};

MiReportTopicsPage.propTypes = {
  row: PropTypes.array,
};

export default MiReportTopicsPage;
