/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  CheckCircle,
  ErrorRounded,
  Pending,
  WatchLater,
} from '@mui/icons-material';
import { styled } from '@mui/material';
import colors from '../../../themes/colors';

const DoneIcon = styled(CheckCircle)(({ theme }) => ({
  color: colors.green.G400,
  fontSize: '14px',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

// const ApprovedIcon = styled(Verified)(({ theme }) => ({
//   color: colors.green.G400,
//   marginLeft: theme.spacing(1),
//   marginRight: theme.spacing(1),
// }));

const ErrorIcon = styled(ErrorRounded)(({ theme }) => ({
  color: colors.red.R250,
  fontSize: '14px',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const PendingIcon = styled(Pending)(({ theme }) => ({
  color: colors.green.G400,
  fontSize: '14px',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const PlannedIcon = styled(WatchLater)(({ theme }) => ({
  color: colors.yellow.Y400,
  fontSize: '14px',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export const reportStatusConfig = {
  READY: { title: 'Report Ready', icon: <DoneIcon fontSize="small" /> },
  ERROR: { title: 'Report Error', icon: <ErrorIcon fontSize="small" /> },
  CREATING: {
    title: 'Creating Report',
    icon: <PendingIcon fontSize="small" />,
  },
  PLANNED: {
    title: 'Planned Report',
    icon: <PlannedIcon fontSize="small" />,
  },
  null: { title: null, icon: null },
  approved: { title: null, icon: null },
  pending: { title: 'Pending', icon: <PendingIcon fontSize="small" /> },
};
