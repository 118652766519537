/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

const { REACT_APP_TIMEZONE } = process.env;

let APPLICATION_TIMEZONE = 'local';

if (REACT_APP_TIMEZONE) {
  APPLICATION_TIMEZONE = REACT_APP_TIMEZONE;
}

export default APPLICATION_TIMEZONE;
export const INPUT_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSS';
export const DEFAULT_FORMAT = 'ddd, MMM D, YYYY';
