/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { useEffect, useState } from 'react';
import {
  List,
  ListItemText,
  Box,
  Typography,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  IconButton,
  Tooltip,
  Badge,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Delete, QuestionAnswer, Sort } from '@mui/icons-material';
import MiReportsAutoComplete from '../autocomplete';
import { StyledListButton } from './miReportsSidebar.styled';
import DialogComponent from '../../../../../components/app.dialog';
import { useTranslation } from 'react-i18next';
import { reportStatusConfig } from '../../reportStatusConfig';
import DisplayTime from '../../../../../components/timezone.component';
import CompanyAvatar from '../companyAvatar';
import APPLICATION_TIMEZONE from '../../../../../constants/timeFormats';

const translationJSONPrefix = 'newMarketIntelligencePage';

const MiReportsSidebar = ({
  myStockPreferences,
  reportKeys,
  isGetReportKeysSuccess,
  handleSelectReport,
  selectedReportId,
  onAskDbSelect,
  handleSelectSymbolChange,
  userInput,
  handleUserInput,
  isGetStockSymbolFetching,
  feature,
  reportIdentifier,
  handleDeletePreference,
  isDeletePreferenceLoading,
}) => {
  const { t } = useTranslation();

  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [deleteTargetKey, setDeleteTargetKey] = useState('');
  const [myPreferences, setMyPreferences] = useState([]);
  const [sortedByName, setSortedByName] = useState(false);

  useEffect(() => {
    if (myStockPreferences) {
      const sortedByDate = [...myStockPreferences].sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      );
      setMyPreferences(sortedByDate);
      setSortedByName(false);
    }
  }, [myStockPreferences]);

  const handleFinalSubmit = () => {
    handleDeletePreference(deleteTargetKey);
    setAlertDialogOpen(false);
  };

  const handleDeletePreferenceSelect = (key) => {
    setDeleteTargetKey(key);
    setAlertDialogOpen(true);
  };

  const handleSort = () => {
    if (sortedByName) {
      const sortedByDate = [...myStockPreferences].sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate),
      );
      setMyPreferences(sortedByDate);
      setSortedByName(false);
    } else {
      // Switch to sorting by name
      const sortedByNameArray = [...myStockPreferences].sort((a, b) =>
        (a.symbol || '').localeCompare(b.symbol || ''),
      );
      setMyPreferences(sortedByNameArray);
      setSortedByName(true);
    }
  };

  return (
    <Box
      sx={{
        //   width: { xs: 250, lg: 320 },
        flexShrink: 0,
        height: '100%',
        borderRight: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DialogComponent
        open={alertDialogOpen}
        setOpen={setAlertDialogOpen}
        title={t(`${translationJSONPrefix}.reports.alertDialog.title`)}
        content={t(`${translationJSONPrefix}.reports.alertDialog.content`)}
        onSubmit={handleFinalSubmit}
        isDeleteLoading={isDeletePreferenceLoading}
        cancelBtnLabel={t(
          `${translationJSONPrefix}.reports.alertDialog.buttons.cancel`,
        )}
        submitBtnLabel={t(
          `${translationJSONPrefix}.reports.alertDialog.buttons.yes`,
        )}
      />

      <Grid container p={2}>
        <Grid
          container
          item
          spacing={1}
          xs={12}
          alignItems="center"
          display="flex"
          justifyContent="space-evenly"
        >
          <Grid item>
            <Tooltip
              title={
                sortedByName ? 'Remove Sorting' : 'Sort Preferences by Name'
              }
            >
              <IconButton onClick={handleSort}>
                <Sort />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item flexGrow={1}>
            {/* <MiReportsAutoComplete /> */}
            <MiReportsAutoComplete
              preferences={myStockPreferences}
              symbolsList={reportKeys}
              handleSelectSymbolChange={handleSelectSymbolChange}
              userInput={userInput}
              handleUserInput={handleUserInput}
              isGetStockSymbolFetching={isGetStockSymbolFetching}
              feature={feature}
            />
          </Grid>
        </Grid>
      </Grid>
      <List sx={{ padding: 0 }}>
        <ListItemButton
          sx={{ backgroundColor: 'secondary.light' }}
          onClick={onAskDbSelect}
        >
          <ListItemIcon>
            <QuestionAnswer sx={{ color: 'secondary.main' }} />
          </ListItemIcon>
          <ListItemText>Ask the database</ListItemText>
        </ListItemButton>
      </List>
      <List sx={{ flexGrow: 1, overflowY: 'auto', padding: 0 }}>
        {isGetReportKeysSuccess &&
          myPreferences?.map((reportKey, idx) => (
            <StyledListButton
              key={idx}
              onClick={() => handleSelectReport(reportKey)}
              selected={
                reportIdentifier === reportKey?.key ||
                reportIdentifier === reportKey?.symbol
              }
            >
              <ListItemAvatar>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    reportKey.status in reportStatusConfig &&
                    reportStatusConfig[reportKey.status].icon && (
                      <Tooltip
                        sx={{ marginTop: 1 }}
                        title={reportStatusConfig[reportKey.status].title}
                      >
                        {reportStatusConfig[reportKey.status].icon}
                      </Tooltip>
                    )
                  }
                >
                  <CompanyAvatar
                    feature={feature}
                    url={reportKey?.logoUrl || reportKey.companyLogoUrl}
                  />
                </Badge>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <Typography variant="subtitle1">
                      {reportKey?.symbol}
                    </Typography>
                    <Typography variant="body1">
                      {reportKey?.name || reportKey?.companyName}{' '}
                    </Typography>
                  </>
                }
                secondary={
                  (reportKey?.status === 'READY' ||
                    reportKey?.status === 'PLANNED' ||
                    reportKey?.status === 'CREATING') &&
                  reportKey?.lastUpdatedDate && (
                    <Typography variant="caption">
                      {t(`${translationJSONPrefix}.sidebar.lastUpdatedOn`)}{' '}
                      <DisplayTime
                        time={reportKey?.lastUpdatedDate}
                        format="ddd, D MMM YYYY HH:mm"
                        timezone={APPLICATION_TIMEZONE}
                      />
                    </Typography>
                  )
                }
              />
              {selectedReportId === reportKey.id && (
                <Tooltip title={'Delete'}>
                  <IconButton
                    //disabled={selectedReportId !== reportKey.id}
                    color="primary"
                    onClick={() =>
                      handleDeletePreferenceSelect(
                        reportKey?.key || reportKey?.symbol,
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </StyledListButton>
          ))}
      </List>
    </Box>
  );
};

MiReportsSidebar.propTypes = {
  myStockPreferences: PropTypes.array,
  handleSelectSymbolChange: PropTypes.func,
  userInput: PropTypes.string,
  handleUserInput: PropTypes.func,
  isGetStockSymbolFetching: PropTypes.bool,
  feature: PropTypes.string,
  reportKeys: PropTypes.array,
  isGetReportKeysSuccess: PropTypes.bool,
  handleDeletePreference: PropTypes.func,
  handleSelectReport: PropTypes.func,
  selectedReportId: PropTypes.string,
  onAskDbSelect: PropTypes.func,
  isDeletePreferenceLoading: PropTypes.bool,
  reportIdentifier: PropTypes.string,
};

export default MiReportsSidebar;
