/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React, { useEffect, useState, useContext } from 'react';
import {
  Toolbar,
  IconButton,
  Container,
  Button,
  Popover,
  Icon,
  Tooltip,
  Grid,
} from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppNavbarProfile } from './app.navbar.profile.component';
import {
  AppNavBar,
  StyledAvatar,
  AvatarLetterWrap,
  BoxWrap,
  BoxWrapMobile,
  LogoWrap,
  StyledModelContainer,
  StyledNameTypography,
  StyledDivider,
  GridItemHorizontal,
  GridContainerHorizontal,
} from './app.navbar.styled';
import { ReactComponent as ProfileIcon } from '../../assets/profile-icon.svg';
import * as PATHS from '../../constants/path';
import * as ACCESS_CONTROL_PATHS from '../../constants/accessControlPaths';
import {
  useLazyGetSearchEnginesQuery,
  useLazyGetFileCollectionsQuery,
} from '../../redux/services/speciphicAsk';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';
import Favicon from 'react-favicon';
import { formatLength } from '../../constants/navBar';
import Dropdown from '../dropdown';
import { FileCollectionContext } from '../../hooks/useContext';
import { setSelectedFileCollection } from '../../redux/reducers/fileCollection.slice';
import {
  USERIDENTIFIER,
  CLIENT,
  AUTH_PROVIDER,
  REDIRECT_URL,
} from '../../constants/auth';
import { GENERAL as GENERAL_CONFIG } from '../../constants/appConfigurationPaths';
import { DASHBOARD as ANALYTICS_DASHBOARD } from '../../constants/analyticsPaths';
import { filterByIds } from '../../utils/functions';
import { CURRENT_COLLECTION_ID } from '../../constants/localStorage';

const AppNavbar = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useAuth();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [getSearchEngines, result] = useLazyGetSearchEnginesQuery();
  const { updateCollectionId, getCollectionId, setFileCollectionsData } =
    useContext(FileCollectionContext);
  const { getSiteConfig, isUnauthorized } = useContext(SiteConfigContext);
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [headerFile, setHeaderFile] = useState('');
  const [favicon, setFavicon] = useState('');

  useEffect(() => {
    const siteConfig = getSiteConfig();

    if (siteConfig) {
      setHeaderFile(siteConfig?.headerLogoFile);
      setFavicon(siteConfig?.favicon);
    }
  }, [getSiteConfig()]);

  const [
    getFileCollections,
    {
      data: fileCollections = [],
      isError: isGetFileCollectionsError,
      isLoading: isGetFileCollectionsLoading,
      isSuccess: isGetFileCollectionsSuccess,
    },
  ] = useLazyGetFileCollectionsQuery();

  useEffect(() => {
    if (auth?.isAuthenticated)
      getFileCollections({
        include: [
          'storageId',
          'name',
          'languageModelId',
          'languageModelName',
          'isEnabled',
          'isVisible',
          'displayOrder',
        ],
        isStoreCreated: true,
      });
  }, [auth?.isAuthenticated]);

  useEffect(() => {
    if (fileCollections) {
      setFileCollectionsData(fileCollections);

      setTimeout(() => {
        let currentCollectionId = localStorage.getItem(CURRENT_COLLECTION_ID);

        if (!currentCollectionId || currentCollectionId === 'undefined')
          currentCollectionId = fileCollections[0]?.id;

        updateCollectionId(currentCollectionId);
      }, 500);
    }
  }, [fileCollections?.length]);

  useEffect(() => {
    const isConversationPath =
      window.location.pathname.includes(`${PATHS.FILE_COLLECTIONS}/`) &&
      window.location.pathname.includes(`/${PATHS.CONVERSATION}/`);

    const isChatPath =
      window.location.pathname.includes(`${PATHS.FILE_COLLECTIONS}/`) &&
      window.location.pathname.includes(`/${PATHS.CONVERSATION}`);
    setDropdownVisible(
      isConversationPath ||
        isChatPath ||
        window.location.pathname === PATHS.HOMEPAGE ||
        window.location.pathname ===
          `${PATHS.SEARCH_METADATA_PAGE}/${getCollectionId()}` ||
        window.location.pathname === PATHS.FAQ ||
        window.location.pathname ===
          `${PATHS.SEARCH_METADATA_PAGE}/${getCollectionId()}/${
            PATHS.METADATA_NOT_ENABLED_ERROR_PAGE
          }` ||
        window.location.pathname === PATHS.READING_ASSISTANT,
    );
  }, [window.location.pathname, getCollectionId()]);

  useEffect(() => {
    if (auth.isAuthenticated && window.location.pathname === PATHS.HOMEPAGE) {
      getSearchEngines();
    }
  }, [auth.isAuthenticated, getSearchEngines, location.pathname]);

  // useEffect(() => {
  //   if (window.location.pathname === '/') {
  //     auth.signinSilent();
  //   }
  // }, []);

  const handleSignInClick = () => {
    auth.signinRedirect();
  };

  const handleLogoutClick = () => {
    auth.signoutRedirect(
      AUTH_PROVIDER && {
        extraQueryParams: {
          client_id: CLIENT,
          response_type: 'code',
          redirect_uri: REDIRECT_URL,
        },
      },
    );
    if (auth.isAuthenticated) {
      auth.removeUser();
    }
    handleCloseUserMenuClick();
    localStorage.clear();
    // navigate(PATHS.LOGOUT);
  };

  const handleProfileClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.PROFILE);
  };

  const handleSearchHistoryClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.SEARCH_HISTORY);
  };

  const handleAnalyticsClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.ANALYTICS + ANALYTICS_DASHBOARD.endpoint);
  };

  const handleReadingAssistantClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.READING_ASSISTANT);
  };

  // const handleSettingsClick = () => {
  //   handleCloseUserMenuClick();
  //   navigate(PATHS.SETTINGS);
  // };

  const handleFileCollectionsClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.FILE_COLLECTIONS);
  };

  const handleDataSourcesClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.DATA_SOURCES);
  };

  const handleFaqClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.FAQ);
  };

  const handleConversationHistoryClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.CONVERSATION_HISTORY);
  };

  const handleAppConfigurationClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.APP_CONFIGURATION + GENERAL_CONFIG.endpoint);
  };

  const handleAccessControlClick = () => {
    handleCloseUserMenuClick();
    navigate(
      `${PATHS.ACCESS_CONTROL}${ACCESS_CONTROL_PATHS.POLICIES.endpoint}`,
    );
  };

  const handleHomeClick = () => {
    navigate(PATHS.HOMEPAGE);
  };

  const handleMarketIntelligenceListedClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.MARKET_INTELLIGENCE);
  };

  const handleMarketIntelligenceClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.MI_REPORTS);
  };

  const handleMarketIntelligenceUnlistedClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.UNLISTED_COMPANY_REPORTS);
  };

  const handleReportSummaryClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.REPORT_SUMMARY_TOPICS);
  };

  const handleReportSourcesClick = () => {
    handleCloseUserMenuClick();
    navigate(PATHS.REPORT_SOURCES);
  };

  const handleOpenUserMenuClick = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenuClick = () => {
    setAnchorElUser(null);
  };

  const itemsForFileCollection = fileCollections
    .filter(
      (item) => typeof item.isVisible === 'undefined' || item.isVisible == true,
    )
    .filter(
      (item) =>
        typeof item.isEnabled === 'undefined' || item.isEnabled === true,
    )
    .map((item) => ({
      label: item.name,
      value: item.id,
      isEnabled: item.isEnabled,
      displayOrder: item.displayOrder,
    }));

  const handleFileChange = (value) => {
    const selectedFC = fileCollections.find(
      (fileCollection) => fileCollection.id === value,
    );

    dispatch(setSelectedFileCollection(selectedFC));
    const collectionName = selectedFC?.name;

    if (
      window.location.pathname ===
      `${PATHS.SEARCH_METADATA_PAGE}/${getCollectionId()}`
    ) {
      navigate(`${PATHS.SEARCH_METADATA_PAGE}/${value}`, {
        state: {
          collectionId: value,
          collectionName: collectionName,
        },
      });
    } else {
      if (
        window.location.pathname ===
        `${PATHS.SEARCH_METADATA_PAGE}/${getCollectionId()}/${
          PATHS.METADATA_NOT_ENABLED_ERROR_PAGE
        }`
      ) {
        navigate(`${PATHS.SEARCH_METADATA_PAGE}/${value}`, {
          state: {
            collectionId: value,
            collectionName: collectionName,
          },
        });
      }
    }
    // setFileCollectionId(value);
    updateCollectionId(value);
  };

  if (isUnauthorized()) return <></>;

  return (
    <AppNavBar ref={ref} position="sticky">
      {favicon?.length > 0 && <Favicon url={favicon} />}
      <Container maxWidth="100%">
        <Toolbar disableGutters>
          <GridContainerHorizontal>
            <Grid item>
              <BoxWrap>
                <LogoWrap onClick={handleHomeClick}>
                  {headerFile ? (
                    <img
                      src={headerFile}
                      alt="Header SVG"
                      style={{ height: '30px' }}
                    />
                  ) : (
                    <></>
                  )}
                </LogoWrap>
              </BoxWrap>
            </Grid>
            {auth.isAuthenticated ? (
              <GridItemHorizontal>
                <Grid item>
                  {dropdownVisible && (
                    <Dropdown
                      items={itemsForFileCollection}
                      onChange={handleFileChange}
                      value={getCollectionId()}
                      noOptionsMessage={t(
                        'trainedquestionAnswerPage.fileCollectionDropdown.noOptionsMessage',
                      )}
                      placeholder={t(
                        'trainedquestionAnswerPage.fileCollectionDropdown.placeholder',
                      )}
                      isLoading={
                        search.get('id') ? false : isGetFileCollectionsLoading
                      }
                      loadingMessage={t(
                        'trainedquestionAnswerPage.fileCollectionDropdown.loadingMessage',
                      )}
                      searchEnginePlaceholder={
                        fileCollections && getCollectionId()
                          ? filterByIds([getCollectionId()], fileCollections)[0]
                              ?.languageModelName
                          : ''
                      }
                      label={false}
                    />
                  )}
                </Grid>
                {dropdownVisible && <StyledDivider orientation="vertical" />}
                <GridItemHorizontal>
                  <BoxWrap>
                    <StyledModelContainer>
                      <StyledNameTypography variant="subtitle1">
                        {auth.user.profile.name
                          ? formatLength(auth.user.profile.name)
                          : t('app.navbar.accountText.primary')}
                      </StyledNameTypography>
                    </StyledModelContainer>
                  </BoxWrap>
                  <IconButton onClick={handleOpenUserMenuClick}>
                    <StyledAvatar>
                      <AvatarLetterWrap>
                        {auth.user.profile.name?.charAt(0) ||
                          auth.user.profile.oid?.charAt(0)}
                      </AvatarLetterWrap>
                    </StyledAvatar>
                  </IconButton>
                  <Popover
                    sx={{ mt: 3 }}
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenuClick}
                  >
                    <AppNavbarProfile
                      userName={auth.user.profile?.name}
                      userEmail={auth.user.profile[USERIDENTIFIER]}
                      profileClick={handleProfileClick}
                      marketIntelligenceListedClick={
                        handleMarketIntelligenceListedClick
                      }
                      marketIntelligenceUnlistedClick={
                        handleMarketIntelligenceUnlistedClick
                      }
                      marketIntelligenceClick={handleMarketIntelligenceClick}
                      reportSourcesClick={handleReportSourcesClick}
                      fileCollectionsClick={handleFileCollectionsClick}
                      logoutClick={handleLogoutClick}
                      dataSourcesClick={handleDataSourcesClick}
                      searchHistoryClick={handleSearchHistoryClick}
                      accessControlClick={handleAccessControlClick}
                      analyticsClick={handleAnalyticsClick}
                      readingAssistantClick={handleReadingAssistantClick}
                      reportSummaryClick={handleReportSummaryClick}
                      faqClick={handleFaqClick}
                      appConfigurationClick={handleAppConfigurationClick}
                      conversationHistoryClick={handleConversationHistoryClick}
                    />
                  </Popover>
                </GridItemHorizontal>
              </GridItemHorizontal>
            ) : (
              <>
                <Grid item sm={4} display={'flex'} justifyContent={'flex-end'}>
                  <BoxWrapMobile>
                    <Tooltip title={t('app.navbar.signInbuttonText')}>
                      <IconButton color="primary" onClick={handleSignInClick}>
                        <ProfileIcon />
                      </IconButton>
                    </Tooltip>
                  </BoxWrapMobile>
                  {/* Desktop devices */}
                  <BoxWrap>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleSignInClick}
                      size="large"
                      endIcon={<Icon component={ProfileIcon} />}
                    >
                      {t('app.navbar.signInbuttonText')}
                    </Button>
                  </BoxWrap>
                </Grid>
              </>
            )}
          </GridContainerHorizontal>
        </Toolbar>
      </Container>
    </AppNavBar>
  );
});

AppNavbar.displayName = 'AppNavbar';

export default AppNavbar;
