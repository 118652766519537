/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Cancel, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Alert } from '@mui/material';
import PropTypes from 'prop-types';

import { useAddDataSourceMutation } from '../../../redux/services/speciphicAsk';
import * as PATHS from '../../../constants/path';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import { ButtonWrapper } from '../addDataSourcePage.styled';

const SubmitButtonGroup = ({ data, onClear, errorMessage }) => {
  const [errorResponse, setErrorResponse] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [
    addDataSource,
    {
      data: result = [],
      isError: isAddDataSourceError,
      isLoading: isAddDataSourceLoading,
      isSuccess: isAddDataSourceSuccess,
    },
  ] = useAddDataSourceMutation();

  const handleAddEmptyCollection = () => {
    setErrorResponse('');
    addDataSource(data)
      .unwrap()
      .then((response) => {})
      .catch((error) => {
        if (error.status === 400) {
          setErrorResponse(error.data.message);
        }
      });
  };

  useEffect(() => {
    if (isAddDataSourceSuccess) {
      dispatch(
        pushToast({
          message: t('addDataSourcePage.alert.create'),
          severity: 'success',
        }),
      );
      navigate(`${PATHS.DATA_SOURCES}/${result.id}`);
    }
  }, [isAddDataSourceSuccess]);

  const handleClearFields = () => {
    setErrorResponse('');
    if (onClear) {
      onClear();
    }
  };

  return (
    <>
      {errorResponse != '' && (
        <Box sx={{ marginTop: 2, display: 'flex' }}>
          <Alert sx={{ width: '100%' }} severity="error">
            {errorResponse}
          </Alert>
        </Box>
      )}
      <ButtonWrapper>
        <Button
          size="medium"
          variant="outlined"
          disabled={
            isAddDataSourceLoading ||
            Object.keys(data['params'])
              .filter((key) => key !== 'region')
              .every((key) => data['params'][key].length <= 0)
          }
          startIcon={<Cancel />}
          onClick={handleClearFields}
        >
          {t('fileUploaderPage.fileCollectionClear')}
        </Button>
        <LoadingButton
          sx={{ marginLeft: 2 }}
          size="medium"
          variant="contained"
          disabled={
            errorMessage != '' ||
            isAddDataSourceLoading ||
            Object.entries(data['params']).some(
              ([key, value]) => value.length === 0,
            )
          }
          onClick={handleAddEmptyCollection}
          loading={isAddDataSourceLoading}
          startIcon={<Save />}
        >
          {t('addDataSourcePage.buttons.createDataSourceButton')}
        </LoadingButton>
      </ButtonWrapper>
    </>
  );
};

SubmitButtonGroup.propTypes = {
  data: PropTypes.object,
  onClear: PropTypes.func,
  errorMessage: PropTypes.string,
};

export default SubmitButtonGroup;
