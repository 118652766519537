/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Avatar,
  Badge,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AutoComplete, StatusWrapper } from './miReportsAutoComponent.styled';
import {
  MARKET_INTELLIGENCE_LISTED_FEATURE,
  MARKET_INTELLIGENCE_UNLISTED_FEATURE,
} from '../../../../../constants/marketIntelligence';
import DisplayTime from '../../../../../components/timezone.component';
import { reportStatusConfig } from '../../reportStatusConfig';
import { useTranslation } from 'react-i18next';
import CompanyAvatar from '../companyAvatar';
import APPLICATION_TIMEZONE from '../../../../../constants/timeFormats';

const translationJSONPrefix = 'newMarketIntelligencePage';

const MiReportsAutoComplete = ({
  symbolsList,
  handleSelectSymbolChange,
  userInput,
  handleUserInput,
  isGetStockSymbolFetching,
  feature,
  preferences,
}) => {
  const { t } = useTranslation();

  const filterOptions = (options, { inputValue }) => {
    const searchString = inputValue?.toLowerCase();
    if (feature === MARKET_INTELLIGENCE_LISTED_FEATURE) {
      return options.filter(
        (option) =>
          option.name?.toLowerCase().includes(searchString) ||
          option.displaySymbol?.toLowerCase().includes(searchString),
      );
    } else if (feature === MARKET_INTELLIGENCE_UNLISTED_FEATURE) {
      return options.filter(
        (option) =>
          option.companyName?.toLowerCase().includes(searchString) ||
          option.key?.toLowerCase().includes(searchString),
      );
    }
  };

  const fetchStockLabel = (option) => {
    if (feature === MARKET_INTELLIGENCE_LISTED_FEATURE) {
      return option?.name;
    } else {
      return option?.companyName;
    }
  };

  return (
    <AutoComplete
      options={symbolsList}
      onChange={handleSelectSymbolChange}
      getOptionLabel={(option) => fetchStockLabel(option)}
      popupIcon={<></>}
      blurOnSelect={true}
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) =>
        option?.symbol === value?.symbol || option?.key === value?.key
      }
      getOptionDisabled={(option) => {
        return preferences.some((pref) =>
          feature === MARKET_INTELLIGENCE_LISTED_FEATURE
            ? option?.symbol === pref.symbol
            : option?.key === pref.key ||
              option?.companyName === pref.companyName,
        );
      }}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.key || option.symbol}>
          <ListItemAvatar>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                option.status in reportStatusConfig &&
                reportStatusConfig[option.status].icon && (
                  <Tooltip title={reportStatusConfig[option.status].title}>
                    {reportStatusConfig[option.status].icon}
                  </Tooltip>
                )
              }
            >
              <CompanyAvatar
                feature={feature}
                url={option?.logoUrl || option?.companyLogoUrl}
              />
            </Badge>
          </ListItemAvatar>

          <ListItemText
            primary={
              <StatusWrapper>
                <Typography variant="subtitle1">
                  {option?.symbol || option?.key}
                </Typography>

                <Typography variant="body1">
                  {option?.name || option?.companyName}{' '}
                </Typography>
              </StatusWrapper>
            }
            secondary={
              option?.lastUpdatedDate == null ||
              option?.lastUpdatedDate == undefined ||
              option?.status !== 'READY' ? (
                <></>
              ) : (
                <span>
                  <Typography variant="caption" fontSize={12}>
                    {t(`${translationJSONPrefix}.sidebar.lastUpdatedOn`)}{' '}
                    <DisplayTime
                      time={option?.lastUpdatedDate}
                      format="ddd, D MMM YYYY HH:mm"
                      timezone={APPLICATION_TIMEZONE}
                    />
                  </Typography>
                </span>
              )
            }
          ></ListItemText>
        </ListItem>
      )}
      loading={isGetStockSymbolFetching}
      loadingText={t(`${translationJSONPrefix}.autocomplete.loadingText`)}
      renderInput={(params) => (
        <Box sx={{ position: `relative` }}>
          <TextField
            size="medium"
            {...params}
            label={t(`${translationJSONPrefix}.autocomplete.label`)}
            inputProps={{
              ...params.inputProps,
              autoComplete: `off`,
              style: { fontSize: `20px`, padding: `16px` },
            }}
            value={userInput}
            onChange={handleUserInput}
          />
        </Box>
      )}
    />
  );
};

MiReportsAutoComplete.propTypes = {
  symbolsList: PropTypes.array,
  handleSelectSymbolChange: PropTypes.func,
  userInput: PropTypes.string,
  handleUserInput: PropTypes.func,
  isGetStockSymbolFetching: PropTypes.bool,
  feature: PropTypes.string,
  preferences: PropTypes.array,
};

export default MiReportsAutoComplete;
