/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
import { createContext, React, useState } from 'react';
import PropTypes from 'prop-types';
import { CURRENT_COLLECTION_ID } from '../constants/localStorage';

const FileCollectionContext = createContext();

const FileCollectionProvider = (props) => {
  const [collectionId, setCollectionId] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [languageModelId, setLanguageModelId] = useState('');
  const [isFileCollectionChanged, setIsFileCollectionChanged] = useState(false);
  const [searchEngine, setSearchEngine] = useState('');
  const [fileCollectionsData, setFileCollectionsData] = useState([]);

  const updateCollectionId = (updatedCollectionId) => {
    if (collectionId === updateCollectionId) return;

    const fileCollection = fileCollectionsData.filter(
      (fc) => fc?.id === updatedCollectionId,
    )[0];

    setCollectionId(updatedCollectionId);
    setLanguageModelId(fileCollection?.languageModelId);
    setCollectionName(fileCollection?.name);
    setIsFileCollectionChanged(!isFileCollectionChanged);

    localStorage.setItem(CURRENT_COLLECTION_ID, updatedCollectionId);
  };

  const updateSearchEngine = (value) => {
    setSearchEngine(value);
  };

  const getCollectionId = () => {
    return collectionId;
  };

  const getLanguageModelId = () => {
    return languageModelId;
  };

  const getCollectionName = () => {
    return collectionName;
  };

  const getSearchEngine = () => {
    return searchEngine;
  };

  const getSelectedFileCollectionStorageId = () => {
    const filteredFC = fileCollectionsData.filter(
      (fc) => fc.id === collectionId,
    );

    console.log('filtered fc', filteredFC);

    if (filteredFC.length > 0) return filteredFC[0]?.storageId;

    return '';
  };

  return (
    <FileCollectionContext.Provider
      value={{
        getCollectionId,
        getLanguageModelId,
        getCollectionName,
        updateCollectionId,
        isFileCollectionChanged,
        setFileCollectionsData,
        updateSearchEngine,
        getSearchEngine,
        getSelectedFileCollectionStorageId,
      }}
    >
      {props.children}
    </FileCollectionContext.Provider>
  );
};
FileCollectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { FileCollectionContext, FileCollectionProvider };
