import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Link,
  Checkbox,
  Autocomplete,
  TextField,
} from '@mui/material';
import { Highlight, PdfHighlighter, PdfLoader } from 'react-pdf-highlighter';
import { Delete, Edit, InsertDriveFile } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';
import { LoadingButton } from '@mui/lab';

import {
  LOAD_TITLE,
  getPDFDataURI,
  truncateString,
} from '../../utils/functions';
import DropdownOne from '../../components/dropdownone';
import {
  useExportPDFMutation,
  useGetSearchEnginesQuery,
  useGetSummaryMutation,
  useLazyGetIngestedFilesQuery,
} from '../../redux/services/speciphicAsk';
import { FileCollectionContext } from '../../hooks/useContext';
import { SPECIPHIC_ASK_API_URL } from '../../constants/apiPaths';
import {
  AssistantContainer,
  ButtonLeft,
  DocTitleField,
  DropDownContainer,
  HighlightContent,
  HighlightContentHeader,
  HighlightFooter,
  HighlightFooterDropDown,
  HighlightHeader,
  HighlightStyled,
  HighlightsContainer,
  HighlightsContainerHeader,
  LinearProgressContainer,
  MainContainer,
  MainContainerLeft,
  MainContainerLeftHeader,
  MainContainerLeftNoFile,
  MainContainerMetric,
  MainContainerRight,
  MainContainerRightHeader,
  ModalFooter,
  PageTitle,
  PdfContainer,
  RTEContainer,
  RTEHeader,
  SummaryDocHeader,
  SummaryFooter,
  TextLink,
} from './readingAssistant.styled';
import RichTextEditor from '../../components/richTextEditor';
import { markdownToHtml } from '../../components/richTextEditor/richTextEditor.component';
import { triggerFileDownload } from '../../utils/functions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import UnauthorizedMessage from '../../components/unauthorized';
import PageContainer from '../../components/pageContainer';
import useDebounce from '../../hooks/useDebounce';
import { DEBOUNCE_DELAY } from '../../constants/debounce';

const translationJSONPrefix = 'readingAssistantPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const ReadingAssistantPage = () => {
  const { t } = useTranslation();
  useEffect(
    () => LOAD_TITLE(document, t(`${translationJSONPrefix}.title`)),
    [],
  );

  const { getCollectionId, getLanguageModelId, getCollectionName } = useContext(
    FileCollectionContext,
  );

  const pdfContainer = useRef();
  const summaryDocLoaderRef = useRef();
  const highlightsEndRef = useRef();

  const { height } = useWindowDimensions();

  const [selectedFile, setSelectedFile] = useState('');
  const [files, setFiles] = useState([]);
  const [highlightsPdf, setHighlightsPdf] = useState([]);
  const [highlights, setHighlights] = useState([]);
  const [languageModels, setLanguageModels] = useState([]);
  const [selectedLM, setSelectedLM] = useState('');
  const [lastSummaryId, setLastSummaryId] = useState('');
  const [pdfDataURI, setPDFDataURI] = useState('');

  const [summaryList, setSummaryList] = useState([]);
  const [summaryDocTitle, setSummaryDocTitle] = useState('Summaries');
  const [searchQuery, setSearchQuery] = useState('');

  const urlPrefix = () =>
    `${SPECIPHIC_ASK_API_URL}/api/v1/file-collections/${getCollectionId()}`;

  const { data: languageModelsDB, error: languageModelError } =
    useGetSearchEnginesQuery();

  const [
    getIngestedFiles,
    {
      data: ingestedFiles,
      error: ingestedFilesError,
      isSuccess: isIFSuccess,
      isFetching: isIFLoading,
    },
  ] = useLazyGetIngestedFilesQuery();

  useDebounce(
    () => {
      getIngestedFiles({
        collectionId: getCollectionId(),
        pageNo: 1,
        pageSize: 100,
        fileType: 'pdf',
        query: searchQuery,
      });
    },
    [searchQuery, getCollectionId()],
    DEBOUNCE_DELAY,
  );

  useEffect(() => {
    if (languageModelsDB) setLanguageModels(languageModelsDB);
    if (languageModels) setSelectedLM(getLanguageModelId());
    setSelectedFile(null);
  }, [languageModelsDB, getLanguageModelId()]);

  const [
    exportPDF,
    {
      data: exportedPDF,
      isSuccess: isExportPDFSuccess,
      isLoading: isExportPDFLoading,
      isError: isExportPDFError,
    },
  ] = useExportPDFMutation();

  useEffect(() => {
    if (exportedPDF)
      triggerFileDownload(
        exportedPDF,
        `${summaryDocTitle}.pdf`,
        'application/pdf',
      );
  }, [exportedPDF]);

  const [
    findSummary,
    {
      data: summarized,
      isSuccess: isGetResultsSuccess,
      isLoading: isSummaryLoading,
      isError: isGetResultsError,
    },
  ] = useGetSummaryMutation();

  useEffect(() => {
    const summary =
      summarized?.answers?.length > 0 ? summarized.answers[0] : '';

    if (
      summary &&
      (summaryList.length === 0 ||
        lastSummaryId !== summaryList[summaryList.length - 1]?.id)
    ) {
      const newSummary = {
        id: lastSummaryId,
        value: markdownToHtml(summary),
        markdownValue: summary,
        checked: true,
        modelId: selectedLM,
        modelName: languageModels.find((m) => m.value === selectedLM).label,
      };

      setSummaryList([...summaryList, newSummary]);
    }
  }, [summarized]);

  useEffect(() => {
    if (ingestedFiles)
      setFiles(ingestedFiles?.ingestedFiles?.map((f, i) => f?.fileName));
  }, [ingestedFiles]);

  useEffect(() => {
    summaryDocLoaderRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }, [summaryList.length]);

  const formatItems = () => [
    ...files.map((file, idx) => ({
      label: file,
      value: file,
    })),
  ];

  const handleFileChange = async (value) => {
    if (!value) return;

    setSelectedFile(value);

    const dataURI = await getPDFDataURI(`${urlPrefix()}/${value}`);
    setPDFDataURI(dataURI);
    setHighlightsPdf([]);
  };

  const addHighlight = (highlight) => {
    const newHighlightPdf = { ...highlight, id: uuid() };
    const newHighlightsPdf = [...highlightsPdf, newHighlightPdf].sort(
      (h1, h2) => {
        const { position: p1 } = h1;
        const { position: p2 } = h2;
        const { boundingRect: r1, pageNumber: pn1 } = p1;
        const { boundingRect: r2, pageNumber: pn2 } = p2;
        if (pn1 === pn2) return r1.y1 - r2.y1;
        return pn1 - pn2;
      },
    );

    const newHighlights = [
      ...highlights,
      {
        ...newHighlightPdf,
        checked: true,
        fileName: selectedFile,
        collectionName: getCollectionName(),
      },
    ];

    setHighlightsPdf(newHighlightsPdf);
    setHighlights(newHighlights);

    // Clear the text selection
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }

    // after some time the state is updated so using setTimeout here
    setTimeout(() => {
      highlightsEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }, 500);
  };

  const handleDeleteHighlight = (hid) => {
    const updatedHighlights = [...highlights.filter((h) => h.id !== hid)];
    const updatedPDFHighlights = [...highlightsPdf.filter((h) => h.id !== hid)];

    setHighlights(updatedHighlights);
    setHighlightsPdf(updatedPDFHighlights);
  };

  const handleSummarize = () => {
    setLastSummaryId(uuid());

    findSummary({
      content: highlights
        .filter((h) => h.checked)
        .map((h) => h.content.text)
        .join(' '),
      languageModelId: selectedLM,
    });

    summaryDocLoaderRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  };

  const handleChangeSummary = (index, value) => {
    const temp_summary_list = [...summaryList];
    temp_summary_list[index].value = value;
    setSummaryList(temp_summary_list);
  };

  const handleCheckHighlight = (checkValue, index) => {
    const tempHighlights = [...highlights];

    tempHighlights[index].checked = checkValue;

    setHighlights(tempHighlights);
  };

  const handleSummaryCheck = (checkValue, index) => {
    const tempSummaries = [...summaryList];

    tempSummaries[index].checked = checkValue;

    setSummaryList(tempSummaries);
  };

  const handleDeleteSummary = (sid) => {
    const updatedSummaryList = [...summaryList.filter((s) => s.id !== sid)];

    setSummaryList(updatedSummaryList);
  };

  if (languageModelError?.status === 403 || ingestedFilesError?.status === 403)
    return (
      <UnauthorizedMessage
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer>
      <AssistantContainer>
        <PageTitle>{t(`${translationJSONPrefix}.pageHeading`)}</PageTitle>

        <MainContainer style={{ height: height - 200 }}>
          <MainContainerLeft>
            <MainContainerLeftHeader>
              <DropDownContainer>
                <Autocomplete
                  value={
                    selectedFile
                      ? { label: selectedFile, value: selectedFile }
                      : null
                  }
                  onChange={(e, value) => handleFileChange(value?.value)}
                  options={formatItems()}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  loading={isIFLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isIFLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      placeholder={
                        isIFLoading
                          ? t(`${translationJSONPrefix}.loadingFiles`)
                          : t(`${translationJSONPrefix}.dropdownPlaceholder`)
                      }
                    />
                  )}
                />
              </DropDownContainer>

              <Button
                variant="contained"
                startIcon={<Edit />}
                disabled={highlightsPdf.length === 0}
                onClick={() => setHighlightsPdf([])}
              >
                {t(`${translationJSONPrefix}.pdfReader.clearHighlight`)}
              </Button>
            </MainContainerLeftHeader>

            <PdfContainer withFile={selectedFile}>
              {selectedFile ? (
                <PdfLoader url={pdfDataURI} beforeLoad={<CircularProgress />}>
                  {(pdfDocument) => (
                    <PdfHighlighter
                      ref={pdfContainer}
                      pdfDocument={pdfDocument}
                      highlights={highlightsPdf}
                      onSelectionFinished={(position, content) =>
                        addHighlight({ content, position, comment: '' })
                      }
                      highlightTransform={(highlight) => (
                        <Highlight
                          isScrolledTo={false}
                          position={highlight.position}
                          comment={highlight.comment}
                        />
                      )}
                    />
                  )}
                </PdfLoader>
              ) : (
                <MainContainerLeftNoFile>
                  <InsertDriveFile />
                  <Box>
                    {t(`${translationJSONPrefix}.pdfReader.noFileSelected`)}
                  </Box>
                </MainContainerLeftNoFile>
              )}
            </PdfContainer>
          </MainContainerLeft>

          <MainContainerRight>
            <MainContainerRightHeader>
              <Box>
                {t(`${translationJSONPrefix}.pdfHighlights.highlights`)}{' '}
                <MainContainerMetric component="span">
                  {highlights.length}
                </MainContainerMetric>
                {', '}
                {t(`${translationJSONPrefix}.pdfHighlights.selected`)}{' '}
                <MainContainerMetric component="span">
                  {highlights.filter((h) => h.checked).length}
                </MainContainerMetric>
              </Box>
              <TextLink onClick={() => setHighlights([])}>
                {t(`${translationJSONPrefix}.pdfHighlights.clearAll`)}
              </TextLink>
            </MainContainerRightHeader>
            <HighlightsContainer>
              {highlights.map((h, i) => (
                <HighlightStyled key={i}>
                  <HighlightHeader>
                    <Checkbox
                      style={{
                        marginRight: 10,
                      }}
                      checked={h.checked}
                      onChange={(e) =>
                        handleCheckHighlight(e.target.checked, i)
                      }
                    />
                    <Box>{h.collectionName}</Box>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteHighlight(h.id)}
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </HighlightHeader>
                  <HighlightContent>
                    <HighlightContentHeader>
                      <Box>{truncateString(h.fileName, 40)}</Box>

                      <Box>
                        {t(`${translationJSONPrefix}.pdfHighlights.page`)}.{' '}
                        {h?.position?.pageNumber}
                      </Box>
                    </HighlightContentHeader>
                    <Box>{h?.content?.text}</Box>
                  </HighlightContent>
                </HighlightStyled>
              ))}
              <Box ref={highlightsEndRef} />
            </HighlightsContainer>

            <HighlightFooter>
              <HighlightFooterDropDown>
                <DropdownOne
                  value={selectedLM}
                  onChange={(value) => setSelectedLM(value)}
                  items={languageModels}
                  label={t(`${translationJSONPrefix}.pdfHighlights.selectLLM`)}
                />
              </HighlightFooterDropDown>
              <LoadingButton
                variant="contained"
                loading={isSummaryLoading}
                disabled={
                  highlights.length === 0 || !selectedLM || isSummaryLoading
                }
                onClick={handleSummarize}
              >
                {t(`${translationJSONPrefix}.pdfHighlights.summarize`)}
              </LoadingButton>
            </HighlightFooter>
          </MainContainerRight>
        </MainContainer>

        {summaryList.length > 0 && (
          <SummaryDocHeader>
            <DocTitleField
              variant="outlined"
              value={summaryDocTitle}
              onChange={(e) => setSummaryDocTitle(e.target.value)}
            />
          </SummaryDocHeader>
        )}
        {summaryList.map((summary, i) => (
          <RTEContainer key={i}>
            <RTEHeader>
              <Checkbox
                style={{
                  marginRight: 10,
                }}
                checked={summary.checked}
                onChange={(e) => handleSummaryCheck(e.target.checked, i)}
              />

              <Box>{summary.modelName}</Box>

              <IconButton
                size="small"
                onClick={() => handleDeleteSummary(summary.id)}
              >
                <Delete fontSize="inherit" />
              </IconButton>
            </RTEHeader>
            <RichTextEditor
              value={summary.value}
              onChange={(value) => handleChangeSummary(i, value)}
            />
          </RTEContainer>
        ))}

        <LinearProgressContainer ref={summaryDocLoaderRef}>
          {isSummaryLoading && <LinearProgress />}
        </LinearProgressContainer>

        {summaryList.length > 0 && (
          <SummaryFooter>
            <LoadingButton
              variant="contained"
              disabled={
                isExportPDFLoading ||
                summaryList.filter((sl) => sl.checked).length === 0
              }
              loading={isExportPDFLoading}
              onClick={() =>
                exportPDF({
                  content: summaryList
                    .filter((sl) => sl.checked)
                    .map((sl) => sl.markdownValue)
                    .join('\n\n'),
                })
              }
            >
              {t(`${translationJSONPrefix}.summary.export`)}
            </LoadingButton>
          </SummaryFooter>
        )}
      </AssistantContainer>
    </PageContainer>
  );
};

export default ReadingAssistantPage;
