/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import MYSQLDBForm from './mysql';
import PropTypes from 'prop-types';

import { MS_SQL } from '../../../constants/dataSources';

const DatabaseForm = ({ dbConfig }) => {
  switch (dbConfig) {
    case MS_SQL:
      return <MYSQLDBForm />;

    default:
      return <>?</>;
  }
};

DatabaseForm.propTypes = {
  dbConfig: PropTypes.string,
};

export default DatabaseForm;
