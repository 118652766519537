/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
export default (builder) =>
  builder.query({
    query: ({ feature, isCommonTopic = undefined }) => {
      const params = new URLSearchParams();
      params.append('feature', feature);

      if (isCommonTopic !== undefined) {
        params.append('isCommonTopic', isCommonTopic);
      }
      return {
        url: `report-summary-topics?${params}`,
        method: 'GET',
      };
    },
    providesTags: ['report-summary-topics'],
  });
