/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { useTranslation } from 'react-i18next';

import { InputField } from '../../settings/fileUploaderPage/fileUploader.page.styled';
import SubmitButtonGroup from './submitButtonGroup';

const FTPForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <InputField
        size="small"
        required
        fullWidth
        id="outlined-required"
        label={'User'}
        //value={name}
        //onChange={handleNameChange}
      />

      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        id="outlined-required"
        label={'Password'}
        // value={accountName}
        //onChange={handleAccountNameChange}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        fullWidth
        required
        id="outlined-required"
        label={'Host Name'}
        //  value={accessKey}
        //  onChange={handleAccessKeyChange}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        fullWidth
        required
        id="outlined-required"
        label={'Directory Name'}
        //  value={accessKey}
        //  onChange={handleAccessKeyChange}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        fullWidth
        required
        id="outlined-required"
        label={'Delay'}
        //  value={accessKey}
        //  onChange={handleAccessKeyChange}
      />
      <SubmitButtonGroup />
    </>
  );
};

export default FTPForm;
