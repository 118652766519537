/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const translationJSONPrefix = 'newMarketIntelligencePage';

const CustomTopicsPanel = ({
  topicsOpen,
  allTopics,
  handleTopicsClose,
  handleSaveMyPreferredCompanyTopics,
  preferredTopics,
  topicsAnchorEl,
  loading,
  isSaveButtonLoading,
}) => {
  const { t } = useTranslation();

  const [selectedTopicIds, setSelectedTopicIds] = useState([]);

  useEffect(() => {
    if (preferredTopics?.length > 0 && allTopics?.length > 0) {
      const topicIds = preferredTopics
        .filter((topic) =>
          allTopics.some((allTopic) => allTopic?.id === topic?.id),
        )
        .map((topic) => topic?.id);
      setSelectedTopicIds(topicIds);
    }
  }, [preferredTopics, allTopics]);

  const handleTopicSelect = (topicId) => {
    setSelectedTopicIds((prevSelected) =>
      prevSelected?.includes(topicId)
        ? prevSelected?.filter((id) => id !== topicId)
        : [...prevSelected, topicId],
    );
  };

  const handleSavePreferredTopics = () => {
    handleSaveMyPreferredCompanyTopics(selectedTopicIds);
  };

  return (
    <Popover
      open={topicsOpen}
      anchorEl={topicsAnchorEl}
      onClose={handleTopicsClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper sx={{ overflow: 'auto', padding: 2 }}>
        {allTopics?.length > 0 ? (
          <Fragment>
            <Typography variant="body1" sx={{ margin: 2 }}>
              {t(`${translationJSONPrefix}.customTopicsPanel.title`)}
            </Typography>
            <Divider />
          </Fragment>
        ) : (
          <Fragment>
            <Alert severity="info">
              {t(`${translationJSONPrefix}.customTopicsPanel.noTopics`)}
            </Alert>

            <Typography variant="body1">
              {t(`${translationJSONPrefix}.customTopicsPanel.navMsg`)}
            </Typography>
          </Fragment>
        )}
        {loading ? (
          <LinearProgress />
        ) : (
          <Fragment>
            {allTopics?.length > 0 && (
              <Fragment>
                <List>
                  {allTopics?.map((topic, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={() => handleTopicSelect(topic?.id)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={selectedTopicIds.includes(topic?.id)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': index }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={topic?.topicName} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => setSelectedTopicIds([])}
                  >
                    {t(
                      `${translationJSONPrefix}.customTopicsPanel.buttons.clear`,
                    )}
                  </Button>
                  <LoadingButton
                    variant="contained"
                    sx={{ marginLeft: 1 }}
                    loading={isSaveButtonLoading}
                    onClick={handleSavePreferredTopics}
                  >
                    {t(
                      `${translationJSONPrefix}.customTopicsPanel.buttons.save`,
                    )}
                  </LoadingButton>
                </Box>
              </Fragment>
            )}
          </Fragment>
        )}
      </Paper>
    </Popover>
  );
};

CustomTopicsPanel.propTypes = {
  preferredTopics: PropTypes.array,
  allTopics: PropTypes.array,
  topicsOpen: PropTypes.bool,
  loading: PropTypes.bool,
  isSaveButtonLoading: PropTypes.bool,
  handleTopicsClose: PropTypes.func,
  handleTopicSelect: PropTypes.func,
  handleSaveMyPreferredCompanyTopics: PropTypes.func,
  topicsAnchorEl: PropTypes.any,
};
export default CustomTopicsPanel;
